
<script>
export default {
  props: {
    id: {
      type: String,
      required: true,
    },
    warnings: {
      type: Object,
      required: true,
    },
  },
};
</script>

<template>
  <div
    class="modal fade"
    :id="id"
    data-backdrop="static"
    data-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-body">
          <div class="account-form-area">
            <button
              type="button"
              class="close-btn"
              data-dismiss="modal"
              aria-label="Close"
            >
              <i class="las la-times"></i>
            </button>
            <h3 class="title">
              {{ $t("text.warnings") }}
            </h3>
            <div class="account-form-wrapper">
              <div class="text-center">
                <p class="wow" v-if="warnings.notenough">
                  {{ $t("text.warning-notenough") }}
                </p>
                <p class="wow" v-if="warnings.unavailable">
                  {{ $t("text.warning-unavailable") }}
                </p>
                <p class="wow" v-if="warnings.notshippable">
                  {{ $t("text.warning-notshippable") }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>