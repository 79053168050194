<script>
import lodash from "lodash";
import SelectCountry from "@/components/field/select_country.vue";
import ShoppingCartWarningsModal from "@/components/modal/shopping_cart_warnings_modal";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";

export default {
  name: "SboppingCart",
  setup() {
    return {
      v$: useVuelidate(),
      lodash: lodash,
    };
  },
  data() {
    return {
      shippingCountry: null,
      loading: false,
      executing: false,

      changingShippingCountry: false,
    };
  },
  validations() {
    return {
      shippingCountry: {
        required,
      },
    };
  },
  watch: {
    "$store.getters.vChallengeCart"() {
      this.setShippingCountry();
    },
  },
  computed: {
    hasErrorChallengeCart() {
      return this.$store.getters.vChallengeCart.warnings.deleted != 0;
    },
  },
  methods: {
    hasErrorProduct(product) {
      return (
        product.warnings.notenough ||
        product.warnings.notshippable ||
        product.warnings.unavailable
      );
    },
    setShippingCountry() {
      this.shippingCountry =
        this.$store.getters.vChallengeCart.shippingCountry != -1
          ? this.$store.getters.vChallengeCart.shippingCountry
          : null;
    },
    clearAll() {
      this.executing = true;
      this.$store
        .dispatch("vchallengeShopCartProductItemAllDelete")
        .then(() => {
          this.loading = true;
          this.executing = false;
          this.$store
            .dispatch("vchallengeShopCartGet")
            .then(() => {
              this.loading = false;
            })
            .catch(() => {
              this.loading = false;
            });
        })
        .catch(() => {
          this.executing = false;
        });
    },
    vchallengeShopCartProductItemDelete(id) {
      this.executing = true;
      this.$store
        .dispatch("vchallengeShopCartProductItemDelete", {
          data: {
            id: id,
          },
        })
        .then(() => {
          this.loading = true;
          this.executing = false;
          this.$store
            .dispatch("vchallengeShopCartGet")
            .then(() => {
              this.loading = false;
            })
            .catch(() => {
              this.loading = false;
            });
        })
        .catch(() => {
          this.executing = false;
        });
    },
    vchallengeShopCartProductItemPut(id, quantity) {
      this.executing = true;
      this.$store
        .dispatch("vchallengeShopCartProductItemPut", {
          data: {
            id: id,
            quantity: quantity,
          },
        })
        .then(() => {
          this.loading = true;
          this.executing = false;
          this.$store
            .dispatch("vchallengeShopCartGet")
            .then(() => {
              this.loading = false;
            })
            .catch(() => {
              this.loading = false;
            });
        })
        .catch(() => {
          this.executing = false;
        });
    },
    vchallengeShopCartShippingCountryPut() {
      this.changingShippingCountry = true;
      this.$store
        .dispatch("vchallengeShopCartShippingCountryPut", {
          data: {
            country: this.shippingCountry,
          },
        })
        .then(() => {
          this.loading = true;
          this.$store
            .dispatch("vchallengeShopCartGet")
            .then(() => {
              this.loading = false;
              this.changingShippingCountry = false;
            })
            .catch(() => {
              this.loading = false;
              this.changingShippingCountry = false;
            });
        })
        .catch(() => {
          this.changingShippingCountry = false;
        });
    },
    vchallengeAvailableGet() {
      this.countdown = null;
      this.$store.dispatch("vchallengeAvailableGet", {}).then((response) => {
        this.countdown = response.data.data;
      });
    },
  },
  mounted() {
    if (!lodash.isNil(this.$store.getters.vChallengeCart)) {
      this.setShippingCountry();
    }
  },
  components: { SelectCountry, ShoppingCartWarningsModal },
};
</script>

<template>
  <div class="inner-hero-section">
    <div class="bg-shape">
      <img src="@/assets/images/elements/inner-hero-shape.png" alt="image" />
    </div>
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <ul class="page-list">
            <li>
              <router-link :to="{ name: 'home' }" tag="a">
                {{ $t("text.home") }}
              </router-link>
            </li>
            <li class="active">
              {{ $t("text.my-cart") }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>

  <section
    class="pb-120 mt-minus-300"
    v-if="!lodash.isNil($store.getters.vChallengeCart)"
  >
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="cart-wrapper">
            <h2 class="cart-wrapper__title">
              {{ $t("text.my-cart") }}
            </h2>
            <div class="row justify-content-lg-between">
              <div class="col-lg-7">
                <div class="ticket-wrapper">
                  <div class="ticket-wrapper__header">
                    <h3>
                      {{ $t("text.products") }}
                    </h3>
                    <button type="button" @click="clearAll()">
                      {{ $t("button.clear-all") }}
                    </button>
                  </div>
                  <div class="ticket-wrapper__body">
                    <template
                      v-for="item in $store.getters.vChallengeCart.items"
                      :key="`item-product-shop-cart-${item._id}`"
                    >
                      <div class="single-row">
                        <div class="left">
                          <h5 class="caption">
                            {{ item.product.title }}
                          </h5>
                          <span>
                            {{
                              `${$t("text.price")}: $${lodash.floor(
                                item.stock.price.value,
                                2
                              )}`
                            }}
                          </span>
                          <span class="ml-5">
                            {{ `${$t("text.quantity")}:` }}
                          </span>
                          <span class="ml-3">
                            <div
                              class="
                                ticket-wrapper__body
                                pt-0
                                pl-0
                                pr-0
                                mb-0
                                pb-0
                              "
                              style="background: transparent"
                            >
                              <div
                                class="single-row pb-o"
                                style="justify-content: start !important"
                              >
                                <div class="action-btns pb-0">
                                  <button
                                    type="button"
                                    :class="item.quantity > 1 ? 'edit-btn' : ''"
                                    :disabled="item.quantity <= 1"
                                    style="
                                      width: 30px !important;
                                      height: 30px !important;
                                    "
                                    @click="
                                      vchallengeShopCartProductItemPut(
                                        item._id,
                                        item.quantity - 1
                                      )
                                    "
                                  >
                                    <i class="las la-minus"></i>
                                  </button>

                                  <button
                                    class="pb-2"
                                    style="
                                      background: transparent;
                                      font-size: 20px;
                                      font-weight: 500;
                                      margin-left: 0 !important;
                                      margin-right: 0 !important;
                                    "
                                  >
                                    {{ item.quantity }}
                                  </button>

                                  <button
                                    type="button"
                                    :class="
                                      item.quantity < item.stock.quantity
                                        ? 'del-btn'
                                        : ''
                                    "
                                    :disabled="
                                      item.quantity >= item.stock.quantity
                                    "
                                    style="
                                      width: 30px !important;
                                      height: 30px !important;
                                    "
                                    @click="
                                      vchallengeShopCartProductItemPut(
                                        item._id,
                                        item.quantity + 1
                                      )
                                    "
                                  >
                                    <i class="las la-plus"></i>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </span>
                        </div>
                        <div class="action-btns right">
                          <template v-if="hasErrorProduct(item)">
                            <button
                              type="button"
                              class="edit-btn"
                              data-toggle="modal"
                              :data-target="`#warnings-${item._id}`"
                            >
                              <i class="las la-exclamation-triangle"></i>
                            </button>

                            <ShoppingCartWarningsModal
                              :id="`warnings-${item._id}`"
                              :warnings="item.warnings"
                            />
                          </template>

                          <button
                            type="button"
                            class="del-btn"
                            :disabled="loading || executing"
                            @click="
                              vchallengeShopCartProductItemDelete(item._id)
                            "
                          >
                            <i class="las la-trash-alt"></i>
                          </button>
                        </div>
                      </div>
                    </template>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 mt-lg-0 mt-4">
                <div class="checkout-wrapper">
                  <div class="checkout-wrapper__header">
                    <h3>
                      {{ $t("text.shipping-country") }}
                    </h3>
                  </div>
                  <div class="checkout-wrapper__body">
                    <form class="personal-details-form">
                      <div class="form-row">
                        <div class="form-group col-lg-12">
                          <SelectCountry v-model="shippingCountry" />

                          <div
                            class="input-errors"
                            v-if="v$.shippingCountry.$invalid"
                          >
                            {{ $t("error.field-required") }}
                          </div>
                        </div>
                      </div>
                    </form>

                    <div class="checkout-wrapper__btn">
                      <button
                        type="submit"
                        class="cmn-btn"
                        :disabled="
                          loading ||
                          executing ||
                          changingShippingCountry ||
                          lodash.isNil(shippingCountry)
                        "
                        @click="vchallengeShopCartShippingCountryPut"
                      >
                        <span
                          v-if="changingShippingCountry"
                          class="spinner-border spinner-border-sm mb-1 mr-2"
                          role="status"
                          aria-hidden="true"
                        ></span>

                        {{ $t("button.update") }}
                      </button>
                    </div>
                  </div>
                </div>

                <div class="checkout-wrapper mt-80">
                  <div class="checkout-wrapper__header">
                    <h3>
                      {{ $t("text.summary") }}
                    </h3>
                  </div>
                  <div class="checkout-wrapper__body">
                    <ul class="price">
                      <li>
                        <div class="left">
                          <h4 class="caption">
                            {{ $t("text.shipping-fee") }}
                          </h4>
                        </div>
                        <div class="right">
                          <span class="price">
                            {{
                              `$${lodash.floor(
                                $store.getters.vChallengeCart.totalShippingFee,
                                2
                              )}`
                            }}
                          </span>
                        </div>
                      </li>
                      <li>
                        <div class="left">
                          <h4 class="caption">
                            {{ $t("text.subtotal") }}
                          </h4>
                          <span>
                            {{
                              `${$t("text.gift-tickets")}: ${lodash.floor(
                                $store.getters.vChallengeCart.cartSubtotal / 25
                              )}`
                            }}
                          </span>
                        </div>
                        <div class="right">
                          <span class="price">
                            {{
                              `$${lodash.floor(
                                $store.getters.vChallengeCart.cartSubtotal,
                                2
                              )}`
                            }}
                          </span>
                        </div>
                      </li>
                      <li>
                        <div class="left">
                          <h4 class="caption">
                            {{ $t("text.total") }}
                          </h4>
                        </div>
                        <div class="right">
                          <span class="price">
                            {{
                              `$${lodash.floor(
                                $store.getters.vChallengeCart.cartTotal,
                                2
                              )}`
                            }}
                          </span>
                        </div>
                      </li>
                    </ul>
                    <div class="checkout-wrapper__btn">
                      <button
                        type="submit"
                        class="cmn-btn"
                        :disabled="
                          loading ||
                          executing ||
                          hasErrorChallengeCart ||
                          lodash.isNil(shippingCountry) ||
                          lodash.isNil($store.getters.vChallengeCart) ||
                          lodash.isEmpty($store.getters.vChallengeCart.items) ||
                          $store.getters.vChallengeCart.shippingCountry == -1
                        "
                        @click="
                          $router.push({
                            name: 'checkout',
                            params: {
                              id: $store.getters.vChallengeCart._id,
                              mode: 7,
                            },
                          })
                        "
                      >
                        {{ $t("button.buy-now") }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>